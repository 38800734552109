import React from 'react';
import {createRoot} from 'react-dom/client'
import { BrowserRouter as Router,Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './Redux';
import Error from './Pages/Error';
import Home from './Pages/Home';
import PortFolio from './Pages/PortFolio';
import Project from './Pages/Project';
import './index.css'
const root = createRoot(document.getElementById('root'));
root.render(
 <Router>
    <Provider store={store}>
      <Routes>
        <Route  exact path='/' element={<Home />}/>
        <Route path='/portfolio' element={<PortFolio />} />
        <Route path= '/portfolio/:name' element={<Project />} />
        <Route path='*' element ={<Error type="404"/>} />
      </Routes>
    </Provider>
 </Router>
)
