export const categories = [
    {
        category: "Tests",
        tags: ["Test unitaire", "Test d'intégration", "Debug", "Jsdoc"]
    },
    {
        category: "UX/UI design",
        tags: ["Design", "Kanban", "User stories", "Acessibilité"]
    },
    {
        category: "API",
        tags: ["REST", "Oauth2"]
    },
    {
        category: "Langages & bibliothèques",
        tags: ["Javascript", "CSS", "Bootstrap", "Sass", "React", "npm"]
    }
]