import { useState } from "react"
import { BiReset } from "react-icons/bi"
import { useDispatch } from "react-redux/es/exports"
import { update } from "../../Redux/Cards/cardsSlicer"
import Button from "../Button"
import { categories } from "../../params/categories"
import { cards } from "../../params/cards"
import './filterMenu.css'

function FilterMenu() {
    const [currButton, setCurrButton] = useState(null)
    const dispatch = useDispatch() 

    function displayButtons(e) {
        if (currButton) {
            currButton.parentNode.children[1].classList.remove("show")
        }
        e.target.parentNode.children[1].classList.add("show")
        setCurrButton(e.target)     
    }

    function resetSelection () {
        if (currButton) {
            currButton.parentNode.children[1].classList.remove("show")
        }
        dispatch(update(cards))
    }

    return(
        <div className="filter-menu">
            {categories.map((cat, index) => (
                <div className="cat-wrapper" key={`cat-${index}`}>
                    <button type="button" className="button-tag main-button" onClick={displayButtons}>{cat.category}</button>
                    <div className="buttons-wrapper">
                        {cat.tags.map((tag, index) => (<Button key={`button-${index}`} button={tag} />))}
                    </div>
                </div>
            ))}
            <button type="button" className="reset-button" onClick={resetSelection} title="Supprimer le filtre">
                <BiReset />
            </button>
        </div>
    )
}

export default FilterMenu