import React from "react"
import Nav from "../Nav"
import { navs } from "../../params/nav"
import './aside.css'

function Aside() {

    navs.social.class = "social-nav-vert"

    return (
        <aside className="aside-social">
            <Nav params={navs.social} />
        </aside>
    )
}

export default Aside