import React from "react"
import { useParams } from 'react-router-dom';
import Header from "../../Components/Header"
import Footer from "../../Components/Footer"
import { navs, icons } from "../../params/nav"
import { projects } from '../../params/projects'
import './project.css'

function Project () {
    let skillsHtml = null
    let githubhtml = null
    let listTitlehtml = null
    let linkHtml = null

    const {name} = useParams()
    const project = projects[name]

    if (project.skills.length > 0) { 
        skillsHtml =  <ul className="project-skills">
                        {project.skills.map((skill, index) => (
                            <div key={`li-${index}`} className="li-wrapper">
                                <span>{icons.check}</span>
                                <li>{skill}</li>
                            </div>
                        ))}
                      </ul>
        listTitlehtml = <p className="project-skills-title">{project.listTitle}</p>
    }

    if (project.github) { githubhtml =  <a href={project.github} title={navs.social.french.github.title} target="_blank" rel="noopener noreferrer">{icons.github}</a>}
    if (project.link.length > 0) {linkHtml = <a href={project.link} title={project.title} target="_blank" rel="noopener noreferrer">{icons.arrow}</a>}

    return (
        <div className="content-strech">
            <Header params={navs.mainNav} />
            <section id="project" className="project">
                <div className="display-wrapper center-wrapp">
                    <h1 className="project-title">{project.title}</h1>
                    <h3 className="project-subtitle">{project.subtitle}</h3>
                    <img src={project.images.src} alt="template" className="project-thumbail"/>
                    <p className="project-describ">{project.text}</p>
                    {listTitlehtml}
                    { skillsHtml }
                    <div className="project-links">
                        { githubhtml }
                        { linkHtml }
                    </div>
                </div>
            </section>
            <Footer params={navs.social} />
        </div>
    )

}

export default Project