import React from "react"
import Tag from "../Tag"
import './card.css'

function Card({card}) {
    let className = ""

    switch(card.type) {
        case "Formation":
            className = "badge-background-orange type-badge"
            break
        case "Personnel":
            className = "badge-background-red type-badge"
            break
        case "Tutoriel":
            className = "badge-background-green type-badge"
            break
        default:
    }

    return (
        <div className="card">
            <span className={className}>{card.type}</span>
            <a href={card.link.link}><img src={card.img.src} alt={card.img.alt} className="thumbail"/></a>
            <div className="card-text-wrapper">
                <p className="card-title">{card.title}</p>
                <div className="tags-wrapper">
                    {card.tags.map((tag, index) => (<Tag key={`tag-${index}`} tag={tag} />))}
                </div>
            </div>
        </div>
    )
}

export default Card