import React from 'react'
import Nav from '../Nav'
import {header} from '../../params/header'
import './header.css'


function Header({params}) {
    return (
        <header id='pageHeader' className='main-header'>
            <a href={header.link}><h1 className='header-title'>{header.french.title}</h1></a>
            <Nav params={params}/>
        </header>
    )
}

export default Header