import React from "react"
import Nav from "../Nav"
import { footer } from "../../params/footer"
import './footer.css'


import './footer.css'

function Footer({params}) {

    params.class = "social-nav"
    return (
        <footer className="main-footer">
            <h3>{footer.french.title}</h3>
            <Nav params={params} />
        </footer>
    )
}

export default Footer