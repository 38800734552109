import React from "react"
import { pics } from '../../params/pics'
import './picframe.css'

function PicFrame ({name}) {

    const pic = pics[name]

    return (
        <div className="pic-frame">
            <img 
                src={pic.src} 
                alt={pic.alt} 
                className={pic.class} 
                style={pic.style}>
                
            </img>
        </div>
    )
}

export default PicFrame