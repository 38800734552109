import React from "react"
import { useSelector } from "react-redux/es/exports"
import Header from "../../Components/Header"
import Footer from "../../Components/Footer"
import Card from "../../Components/Card"
import FilterMenu from "../../Components/FilterMenu"
import { navs } from "../../params/nav"
import './portfolio.css'

function PortFolio() {
    navs.mainNav.french.portfolio.activeClass = 'underline'
    const cardsState = useSelector(state => state.cards.value)

    return (
        <div className="content-strech">
            <Header params={navs.mainNav} />
            <section id="portfolio" className="portfolio">
                <div className="display-wrapper">
                    <h1 className="port-folio-title">PortFolio</h1>
                    <FilterMenu />
                    <section className="table-wrapper">
                        <div className="cards-wrapper">
                            {cardsState.map((card, index) => ( <Card key={`card-${index}`} card={card} />))}
                        </div>
                    </section>
                </div>
            </section>
            <Footer params={navs.social}/>
        </div>
    )
}

export default PortFolio