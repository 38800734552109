import React from "react"
import { useDispatch } from "react-redux/es/exports"
import { update } from "../../Redux/Cards/cardsSlicer"
import { cards } from "../../params/cards"
import './button.css'

function Button ({button}) {
    const dispatch = useDispatch()

    function filterCards(e) {
        let filtCards = null
        e.target.innerText !== "Tous"? filtCards = cards.filter((card) => card.tags.includes(e.target.innerText)): filtCards = cards
        dispatch(update(filtCards))
    }

    return (
        <button type="button" className="button-tag sub-button" onClick={filterCards}>{button}</button>
    )
}

export default Button