import ohmyfoodPic from '../assets/small/ohmyfood.jpg'
import fisheyePic from '../assets/small/fisheye.jpg'
import petitsplatsPics from '../assets/small/petitsPlats.jpg'
import billedPic from '../assets/small/billed.jpg'
import learnAtHomePic from '../assets/small/learnathome.jpg'
import kasRESTc from '../assets/small/kasa.jpg'
import sportseePic from '../assets/small/sportsee.jpg'
import argentbankPic from '../assets/small/argentbank.jpg'
import wealthhealthPic from '../assets/small/wealthhealth.jpg'
import nicolasduquesnePic from '../assets/small/nicolasduquesne.jpg'
import twitchappPic from '../assets/small/twitch.jpg'
import minecraftPic from '../assets/small/minecraft.jpg'


export const cards = [
    {
        title: "Ohmyfood",
        type: "Formation",
        img: {
            src: ohmyfoodPic,
            alt: "Ohmyfood"
        },
        link: {
            link: "/portfolio/Ohmyfood",
            title: "Link 1"
        },
        tags: ["Sass","Animations", "CSS"]
    },
    {
        title: "FishEye",
        type: "Formation",
        img: {
            src: fisheyePic,
            alt: "FishEye"
        },
        link: {
            link: "/portfolio/FishEye",
            title: "Link 1"
        },
        tags: ["Javascript", "Evènements", "Acessibilité", "CSS"]
    },
    {
        title: "Les Petits Plats",
        type: "Formation",
        img: {
            src: petitsplatsPics,
            alt: "Les Petits Plats"
        },
        link: {
            link: "/portfolio/LesPetitsPlats",
            title: "Link 1"
        },
        tags: ["Javascript", "Algorithmes", "Evènements", "CSS"]
    },
    {
        title: "Billed",
        type: "Formation",
        img: {
            src: billedPic,
            alt: "Billed"
        },
        link: {
            link: "/portfolio/Billed",
            title: "Link 1"
        },
        tags: ["Javascript", "Debug", "Test unitaire", "Test d'intégration", "Evènements", "CSS"]
    },
    {
        title: "Learn@Home",
        type: "Formation",
        img: {
            src: learnAtHomePic,
            alt: "Learn@Home"
        },
        link: {
            link: "/portfolio/LearnAtHome",
            title: "Link 1"
        },
        tags: ["Design", "Kanban", "User stories", "CSS"]

    },
    {
        title: "Kasa",
        type: "Formation",
        img: {
            src: kasRESTc,
            alt: "Kasa"
        },
        link: {
            link: "/portfolio/Kasa",
            title: "Link 1"
        },
        tags: ["React", "Evènements", "CSS"]
    },
    {
        title: "SportSee",
        type: "Formation",
        img: {
            src: sportseePic,
            alt: "SportSee"
        },
        link: {
            link: "/portfolio/SportSee",
            title: "Link 1"
        },
        tags: ["React", "Recharts", "Dashboard", "REST", "Evènements", "Sass", "CSS"]
    },
    {
        title: "Argent Bank",
        type: "Formation",
        img: {
            src: argentbankPic,
            alt: "Argent Bank"
        },
        link: {
            link: "/portfolio/ArgentBank",
            title: "Link 1"
        },
        tags: ["React", "Oauth2", "REST", "Jsdoc", "Redux", "Evènements", "CSS"]
    },
    {
        title: "Wealth Health",
        type: "Formation",
        img: {
            src: wealthhealthPic,
            alt: "Wealth Health"
        },
        link: {
            link: "/portfolio/WealthHealth",
            title: "Link 1"
        },
        tags: ["React", "npm", "Package", "Jsdoc", "Redux", "Evènements", "CSS"]
    },
    {
        title: "Nicolas Duquesne",
        type: "Personnel",
        img: {
            src: nicolasduquesnePic,
            alt: "Nicolas Duquesne"
        },
        link: {
            link: "/portfolio/NicolasDuquesne",
            title: "Link 1"
        },
        tags: ["React", "Jsdoc", "Redux", "Evènements", "Algorithmes", "MongoDB", "CSS"]
    },
    {
        title: "Overlay Twitch",
        type: "Personnel",
        img: {
            src: twitchappPic,
            alt: "Overlay Twitch"
        },
        link: {
            link: "/portfolio/OverlayTwitch",
            title: "Link 1"
        },
        tags: ["React", "Jsdoc", "Redux", "Evènements", "REST", "Twitch", "Oauth2", "CSS"]
    },
    {
        title: "Minecraft like",
        type: "Tutoriel",
        img: {
            src: minecraftPic,
            alt: "minecraft like"
        },
        link: {
            link: "/portfolio/MinecraftLike",
            title: "Link 1"
        },
        tags: ["React", "nanoid", "zustand", "Three", "CSS"]
    }

]