import { configureStore } from '@reduxjs/toolkit'
import cardsReducer from './Cards/cardsSlicer'


const store = configureStore({
    reducer: {
        cards:cardsReducer,
    }
})

export default store