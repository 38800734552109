import ohmyfoodPic from '../assets/large/ohmyfood.jpg'
import fisheyePic from '../assets/large/fisheye.jpg'
import petitsplatsPics from '../assets/large/petitsPlats.jpg'
import billedPic from '../assets/large/billed.jpg'
import learnAtHomePic from '../assets/large/learnathome.jpg'
import kasaPic from '../assets/large/kasa.jpg'
import sportseePic from '../assets/large/sportsee.jpg'
import argentbankPic from '../assets/large/argentbank.jpg'
import wealthhealthPic from '../assets/large/wealthhealth.jpg'
import nicolasduquesnePic from '../assets/large/nicolasduquesne.jpg'
import twitchappPic from '../assets/large/twitch.jpg'
import minecraftPic from '../assets/large/minecraft.jpg'

export const projects = {
    Ohmyfood:{
        type: "school",
        title: "Ohmyfood!",
        subtitle: "Une page web avec des animations CSS",
        text: `Ce projet se concentre sur les effets et animations en css`,
        listTitle: "Compétences développées lors ce projet",
        skills: ["Mettre en œuvre des effets CSS graphiques avancés", 
                 "Assurer la cohérence graphique d'un site web",
                 "Mettre en place une structure de navigation pour un site web",
                 "Mettre en place son environnement Front-End",
                 "Utiliser un système de gestion de versions pour le suivi du projet et son hébergement"
                ],
        images: {
            src: ohmyfoodPic
        },
        link: "https://ohmyfood-training.netlify.app/",
        github: "https://github.com/NicolasDuquesne2/NicolasDuquesne_3_23082021.git"
    },
    FishEye: {
        type: "school",
        title: "FishEye",
        subtitle: "Une application web orientée vers l'accessibilité. Les évènements sont pris en charge par javascript",
        text: `Ce projet se concentre sur l'accessibilité d'un site respectant les normes W3C, les Web Content Accessibility Guidelines. Les évènement sont pris en charge par javascript`,
        listTitle: "Compétences développées lors ce projet",
        skills: ["Assurer l'accessibilité d'un site web", 
                 "Développer une application web modulaire avec des design patterns",
                 "Ecrire du code JavaScript maintenable",
                 "Gérer les évènements d'un site avec JavaScript"
                ],
        images: {
            src: fisheyePic
        },
        link: "https://fisheye-training.netlify.app/",
        github: "https://github.com/NicolasDuquesne2/Nicolas_6_15102021.git"
    },
    LesPetitsPlats: {
        type: "school",
        title: "Les Petits Plats",
        subtitle: "Une application web conscrée à l'algorithmie des tris et filtres",
        text: `Cette application se concentre sur la comparaison de performances 
               d'algorithmes fait maison et d'algorithms avec les fonctions intégrées à javascript.`,
        listTitle: "Compétences développées lors ce projet",
        skills: ["Analyser un problème informatique", 
                 "Développer un algorithme pour résoudre un problème",
                ],
        images: {
            src: petitsplatsPics
        },
        link: "https://petitsplats-training.netlify.app/",
        github: "https://github.com/NicolasDuquesne2/Nicolas_6_15102021.git"
    },
    Billed: {
        type: "school",
        title: "Billed",
        subtitle: "Une application web conscrée aux tests unitaires et d'intégration",
        text: `Cette application se concentre sur la correction des bugs, des tests automatisés unitaires et d'intégration.`,
        listTitle: "Compétences développées lors ce projet",
        skills: ["Débugger une application web avec le Chrome Debugger", 
                 "Ecrire des tests d'intégration avec JavaScript",
                 "Ecrire des tests unitaires avec JavaScript",
                 "Rédiger un plan de test end-to-end manuel"
                ],
        images: {
            src: billedPic
        },
        link: "",
        github: "https://github.com/NicolasDuquesne2/Nicolas_Duquesne_9_24012022.git"
    },
    LearnAtHome: {
        type: "school",
        title: "Dev4U",
        subtitle: "Il s'agit d'un ensemble de documents préparatoires à un projet",
        text: `L'ensemble de documents disponibles contient un diagrammes de cas d'usage, des user stories, des maquettes de site et lien 
               vers un tableau kanban sur Notion.`,
        listTitle: "Compétences développées lors ce projet",
        skills: ["Contribuer à un projet en utilisant une méthodologie agile", 
                 "Créer une maquette pour un client",
                 "Identifier les besoins de l'application à partir d'un cahier des charges",
                 "Modéliser une solution technique pour un client"
                ],
        images: {
            src: learnAtHomePic
        },
        link: "",
        github: "https://github.com/NicolasDuquesne2/Projet-10.git"
    },
    Kasa: {
        type: "school",
        title: "Kasa",
        subtitle: "Application de location de logement privé",
        text: `Cette application récupère un fichier Json avec Fetch et propose une gallerie d'images par logement`,
        listTitle: "Compétences développées lors ce projet",
        skills: ["Développer les routes d'une application web avec React Router",
                 "Initialiser une application web avec un framework",
                 "Créer des composants avec React"
                ],
        images: {
            src: kasaPic
        },
        link: "https://kasa-training.netlify.app/",
        github: "https://github.com/NicolasDuquesne2/Nicolas_Duquesne_11_21032022.git"
    },
    SportSee: {
        type: "school",
        title: "SportSee",
        subtitle: "Cette application récupère des données d'un backend avec une api REST",
        text: `En plus de respecter de façon habituelle le design responsive, cette application fait des call api. 
               Le travail est documenté avec jsdoc`,
        listTitle: "Compétences développées lors ce projet",
        skills: ["Assurer la qualité des données d'une application",
                 "Développer des éléments graphiques avancés à l'aide de bibliothèques JavaScript",
                 "Interagir avec un service Web",
                 "Produire de la documentation technique pour une application"
                ],
        images: {
            src: sportseePic
        },
        link: "",
        github: "https://github.com/NicolasDuquesne2/Nicolas_Duquesne_12_15042022.git"
    },
    ArgentBank: {
        type: "school",
        title: "Argent Bank",
        subtitle: "Cette application récupère des données d'un backend avec une api REST et prend en charge l'authentification",
        text: `En plus de respecter de façon habituelle le design responsive, cette application récupère les données avec des call api et 
               prend en charge l'authentification`,
        listTitle: "Compétences développées lors ce projet",
        skills: ["Implémenter un gestionnaire d'état dans une application React",
                 "Intéragir avec une API",
                 "Modéliser une API",
                 "S'authentifier à une API"
                ],
        images: {
            src: argentbankPic
        },
        link: "",
        github: "https://github.com/NicolasDuquesne2/Nicolas_Duquesne_13_03062022.git"
    },
    WealthHealth: {
        type: "school",
        title: "Wealth Health",
        subtitle: "Application React dans un but de réduction de dette technologique, intégrant la création d'un package npm",
        text: `Cette application simple a pour but de remplacer une application en javascript & jquery. 
               Il s'agissait aussi de créer  et de publier un package npm et de l'intégrer au nouveau projet`,
        listTitle: "Compétences développées lors ce projet",
        skills: ["Analyser la performance d'une application web",
                 "Déployer une application front-end",
                 "Programmer en JavaScript avec la programmation fonctionnelle",
                 "Refondre une application pour réduire la dette technique"
                ],
        images: {
            src: wealthhealthPic
        },
        link: "",
        github: "https://github.com/NicolasDuquesne2/NicolasDuquesne_14_06072022.git"
    },
    NicolasDuquesne: {
        type: "personnal",
        title: "Nicolas Duquesne",
        subtitle: "Vous êtes ici sur ce site-portefolio",
        text: `Il s'agit de mon site en front-end et comprenant un peu de tris, de filtres pour la partie portefolio`,
        skills: [],
        images: {
            src: nicolasduquesnePic
        },
        link: "",
        github: ""
    },
    OverlayTwitch: {
        type: "personnal",
        title: "Overlay Twitch",
        subtitle: "Un overlay pour Twitch faisant des appels à l'api de Twitch",
        text: `Cet overlay pour Twitch n'est pas un site et ne contient donc pas de lien inter-pages. Cette application en React s'utilise 
               avec OBS Studio et contient par défaut une page d'accueil, une page de diffusion et une page de fin de live. 
               Cette application fait des appels api à Twitch avec authentification`,
        skills: [],
        images: {
            src: twitchappPic
        },
        link: "",
        github: "https://github.com/NicolasDuquesne2/overlaytwitch.git"
    },
    MinecraftLike:{
        type: "Tutoriel",
        title: "Minecraft like",
        subtitle: "Un proto Minecraft Like issu d'un tutoriel",
        text: `Cette application imite les bases de gameplay d'un Minecraft. 
               Elle s'appuie sur la bibliothèque 3D Three, pour React. Elle Utilise Zesland comme gestionnaire d'état
               et nanoid, un générateur d'ID aléatoire. Vous pouvez trouver le tutoriel ici : https://youtu.be/qpOZup_3P_A`,
        skills: [],
        images: {
            src: minecraftPic
        },
        link: "https://minecraft-like.netlify.app",
        github: "https://github.com/NicolasDuquesne2/react-minecraft-like"
    }
}
    