import React from "react"
import './nav.css'

function Nav ({params}) {
    const className = params.class
    const links = Object.entries(params.french)
    const linkWrapperClass = params.linkclass

    
    if (className === "main-nav") {
        return (
            <nav className={className}>
                {links.map((link, index) => {
                    return (
                        <div key={`link-${index}`} className={linkWrapperClass}>
                            <a href={link[1].route}>{link[1].text}</a>
                            <span className={link[1].activeClass}></span>
                        </div>
                    )
            
                })}
            </nav>
        )
    }

    if (className === "social-nav" || className === "social-nav-vert") {
        return (
            <nav className={className}>
                {links.map((link, index) => {
                    if (link[0] !== "mail") {
                        return (
                            <a key={`link-${index}`} href={link[1].link} title={link[1].title} target="_blank" rel="noopener noreferrer">{link[1].ico}</a>
                        )
                    } else {
                        return (
                            <a key={`link-${index}`} href={link[1].link} title={link[1].title}>{link[1].ico}</a>
                        )
                    }
                })}
            </nav>
        )
    }
}

export default Nav