import React from "react"
import Header from "../../Components/Header"
import Footer from "../../Components/Footer"
import Aside from "../../Components/Aside"
import Icon from "../../Components/Icon"
import PicFrame from "../../Components/PicFrame"
import { navs } from "../../params/nav"
import { home } from "../../params/home"
import { techs, softs } from "../../params/skills"
import './home.css'

function Home () {
    let asidehtml = null
    let philosophyPic = null
    let forwardPic = null

    navs.mainNav.french.home.activeClass = 'underline'

    if(window.innerWidth >= 576 ) {
        asidehtml= <Aside />
    }

    if (window.innerWidth >= 992) {
        philosophyPic = "philisophyMed"
        forwardPic = "forwardMed"
    } else {
        philosophyPic = "philisophySmall"
        forwardPic = "forwardSmall"
    }

    return (
        <div className="content">
            <Header params={navs.mainNav}/>
            {asidehtml}
            <section id="home" className="home">
                <section className="hero">
                    <div className="hero-text-wrapper text-wrapper">
                        <h1 className="hero-title">{home.hero.title}</h1>
                        <p className="hero-text">{home.hero.phrase}</p>
                    </div>
                </section>
                <article className="article">
                    <section id="aboutme" className="section">
                        <h2 className="section-title">{home.aboutme.title}</h2>
                        <div className="about-text-wrapper text-wrapper invert">
                            <p className="section-text">{home.aboutme.text}</p>
                            <span className="line line-short"></span>
                            <PicFrame name="photo" />
                        </div>
                    </section>
                    <section id="philosophy" className="section">
                        <h2 className="section-title">{home.values.title}</h2>
                        <div className="about-text-wrapper text-wrapper">
                            <p className="section-text">{home.values.text}</p>
                            <span className="line line-short"></span>
                            <PicFrame name={philosophyPic} />
                        </div>
                    </section>
                    <section id="forward" className="section">
                        <h2 className="section-title">{home.forward.title}</h2>
                        <div className="about-text-wrapper text-wrapper invert">
                            <p className="section-text">{home.forward.text}</p>
                            <span className="line line-short"></span>
                            <PicFrame name={forwardPic} />
                        </div>
                    </section>
                </article>
                <section id="tech" className="tech section">
                    <div id="stack" className="tech-stats-wrapper">
                        <div className="text-wrapper">
                            <h2 className="section-title">{home.stack.title}</h2>
                        </div>
                        <div className="skills-wrapper">
                            {techs.map((tech, index) => (
                                <Icon key={`icon-${index}`} data={tech}/>
                            ))
                            }
                        </div>
                    </div>
                    <span className="line line-long"></span>
                    <div id="softs" className="tech-stats-wrapper">
                        <div className="text-wrapper">
                            <h2 className="section-title">{home.softs.title}</h2>
                        </div>
                        <div className="skills-wrapper">
                            {softs.map((soft, index) => (
                               <Icon key={`icon-${index}`} data={soft}/>
                            ))
                            }
                        </div>
                    </div>
                </section>
            </section>
            <Footer params={navs.social}/>
        </div>
    )
}

export default Home