import { createSlice } from '@reduxjs/toolkit'
import { cards } from "../../params/cards"

export const cardsSlice = createSlice( {
    name: 'form',
    initialState: {
        value: cards,
    },
    reducers: {

        update: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { update } = cardsSlice.actions
export default cardsSlice.reducer