import './icon.css'

function Icon({data}) {
    return (
        <div className="image-wrapper" >
            <img src={data.icon} alt={data.title}  className="tech-icon"/>
            <div className="tooltip">
                <span className="tooltiptext">{data.title}</span>
            </div>
        </div>
    )
}

export default Icon