import bootstrapIco from '../assets/small/bootstrap.png'
import canvaIco from '../assets/small/canva.png'
import cssIco from '../assets/small/css.png'
import figmaIco from '../assets/small/figma.png'
import gitIco from '../assets/small/git.png'
import googleSheetsIco from '../assets/small/google-sheets.png'
import javascriptIco from '../assets/small/javascript.png'
import libreOfficeIco from '../assets/small/libreoffice.png'
import mysqlIco from '../assets/small/mysql.png'
import netlifyIco from '../assets/small/netlify.png'
import notionIco from '../assets/small/notion.png'
import npmIco from '../assets/small/npm.png'
import numpyIco from '../assets/small/numpy.png'
import pandasIco from '../assets/small/pandas.png'
import pythonIco from '../assets/small/python.png'
import reactIco from '../assets/small/react.png'
import sassIco from '../assets/small/sass.png'
import spyderIco from '../assets/small/spyder.png'
import sqliteIco from '../assets/small/sqlite.png'
import excelIco from '../assets/small/excel.png'
import htmlIco from '../assets/small/html.png'
import gitHubIco from '../assets/small/github.png'
import inkscapeIco from '../assets/small/Inkscape.png'


export const techs = [
    {
        title: "HTML",
        icon: htmlIco
    },
    {
        title: "CSS",
        icon:cssIco
    },
    {
        title: "Javascript",
        icon: javascriptIco
    },
    {
        title: "React",
        icon: reactIco
    },
    {
        title: "Sqlite",
        icon: sqliteIco
    },
    {
        title: "MySQL",
        icon: mysqlIco 
    },
    {
        title: "Bootstrap",
        icon: bootstrapIco
    },
    {
        title: "Python",
        icon: pythonIco
    },
    {
        title: "Numpy",
        icon: numpyIco
    },
    {
        title: "Pandas",
        icon: pandasIco
    },
    {
        title: "Sass",
        icon: sassIco
    }

]

export const softs = [
    {
        title: "Figma",
        icon: figmaIco
    },
    {
        title: "Canva",
        icon: canvaIco
    },
    {
        title: 'Git',
        icon: gitIco
    },
    {
        title: 'Github',
        icon: gitHubIco
    },
    {
        title: "Google sheets",
        icon: googleSheetsIco
    },
    {
        title: "Libre office Calc",
        icon: libreOfficeIco
    },
    {
        title: "Excel",
        icon: excelIco
    },
    {
        title: "Netlify",
        icon: netlifyIco
    },
    {
        title: "Notion",
        icon: notionIco
    },
    {
        title: "Npm",
        icon: npmIco
    },
    {
        title: "Spyder",
        icon: spyderIco
    },
    {
        title: 'Inkscape',
        icon: inkscapeIco
    }
]