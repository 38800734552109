export const home = {
    hero: {
        title: "Nicolas Duquesne",
        phrase: "Développeur front end"
    },
    aboutme: {
        title: "Faisons Connaissance",
        text: `Au départ spécialisé dans le traitement et l'interprétation des données économiques, 
               mes missons m'ont ammenées à réaliser et a automatiser des applications Excel pour du suivi d'activités principalement.
               Après avoir renfocé mes compétences en veille stratégique, j'ai offert mes services en automatisation de tableurs et en
               conseil sur la gestion et la représentation des données. C'est en prenant goût pour la programmation que je me suis lancé 
               dans la formation à distance de développeur front end proposé par Openclassrooms. Une formation spécialisée en React et
               composée de 14 projets à réaliser pour apprendre Le HTML,  le CSS, javascript et React.`
    },
    values: {
        title: "Ma vision",
        text: `Dans un monde en constante évolution l'adaptabilité et la capacité de se remettre en question sont cruciaux.
               C'est dans cette optique de dynamisme que j'envisage les missions. Les technologies évoluent et remmettent en cause les 
               routines. Que seraient des missions sans travail d'équipe coordonné et efficace ? Mes valeurs autour du travail d'équipe se
               concentrent dans la loyauté envers le groupe, la solidarité pour aider son prochain en difficulté et la bienveillance pour une
               communication non violente`
    },
    forward: {
        title: "Allons plus loin",
        text: `Etant attiré par la nouveauté, il sera naturel pour moi de travailler avec un autre framework en Front end et avec d'autres outils autour
               du développement en Front. Plûtôt attiré par le backend, il est question que je réalise une petite application en node.js avec sont front end.
               A court terme, je voudrais donc évoluer vers des missions comprenant du backend (avec ou sans front)`
    },
    stack: {
        title: "Ma stack actuelle",
        text: ""
    },
    softs: {
        title: "Les logiciels",
        text: ""
    }
}