import { AiFillLinkedin } from "react-icons/ai"
import { AiFillGithub } from "react-icons/ai"
import { AiFillMail } from "react-icons/ai"
import { SiCanva } from "react-icons/si"
import { RiFileUserFill } from "react-icons/ri"
import { BsArrow90DegRight} from "react-icons/bs"
import { BsCheck2Circle } from "react-icons/bs"


export const icons = {
    linkedin: <AiFillLinkedin />,
    github: <AiFillGithub />,
    doyoubuzz: <RiFileUserFill />,
    canva: <SiCanva />,
    mail: <AiFillMail />,
    arrow: <BsArrow90DegRight />,
    check: <BsCheck2Circle />
}


export const navs = {
    mainNav: {
        class: "main-nav",
        linkclass: "link-wrapper",
        french:{
            home: {
                activeClass: '',
                route: '/',
                text: "Accueil"
            },
            portfolio: {
                activeClass: '',
                route: '/portfolio',
                text: 'Mes réalisations'
            }
        }
    },

    social: {
        class: "social-nav",
        linkclass: "social-link-wrapper",
        french: {
            linkedin: {
                link: 'https://www.linkedin.com/in/nicolas-duquesne-56735424b',
                title: 'LinkedIn',
                ico: <AiFillLinkedin />
            },
            github: {
                link: 'https://github.com/NicolasDuquesne2',
                title: 'Github',
                ico: <AiFillGithub />
            },
            doyoubuzz: {
                link: 'https://www.doyoubuzz.com/nicolas-duquesne',
                title: 'Doyoubuzz',
                ico: <RiFileUserFill />
            },
            canva: {
                link: 'https://www.canva.com/design/DAFLoA24TA8/3m5cW20IejOtzPxdDLqrgQ/view?utm_content=DAFLoA24TA8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink',
                title: 'Canva',
                ico: <SiCanva />
            },
            mail: {
                link: 'mailto:nicolas.duquesne@caramail.fr',
                title: 'Mail',
                ico: <AiFillMail />
            }
        }
    }
}