import photo from '../assets/small/myphoto.jpg'
import philisophySmall from '../assets/small/subjectivity-g44be504a9_640.jpg'
import philisophyMed from '../assets/medium/subjectivity-g47821b4b1_1280.jpg'
import forwardSmall from '../assets/small/board-ge3330db96_640.jpg'
import forwardMed from '../assets/medium/board-g334771985_1280.jpg'

export const pics = {
    photo: {
        src: photo,
        class: "great-photo",
        alt: 'Nicolas Duquesne',
        style: {
            padding: "10px",
            border: "white",
            borderStyle: "double",
            width: "164px",
            height: "276px"
        }
    },
    philisophySmall : {
        src: philisophySmall,
        class: "great-photo",
        alt: "Image montrant une division de la subjectivité par l'objectivité",
        style: {
            padding: "10px",
            border: "white",
            borderStyle: "double",
            width: "256px",
            height: "170px"
        }
    },
    philisophyMed : {
        src: philisophyMed,
        class: "great-photo",
        alt: "Image montrant une division de la subjectivité par l'objectivité",
        style: {
            padding: "10px",
            border: "white",
            borderStyle: "double",
            width: "384px",
            height: "255px"
        }
    },
    forwardSmall: {
        src: forwardSmall,
        class: "great-photo",
        alt: "Image un enfant devant un tableau noir sur lequel est inscrit look forward",
        style: {
            padding: "10px",
            border: "white",
            borderStyle: "double",
            width: "256px",
            height: "132px"
        }
    },
    forwardMed: {
        src: forwardMed,
        class: "great-photo",
        alt: "Image un enfant devant un tableau noir sur lequel est inscrit look forward",
        style: {
            padding: "10px",
            border: "white",
            borderStyle: "double",
            width: "384px",
            height: "198px"
        }
    }
}