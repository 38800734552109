import React from "react"
import { TbFaceIdError } from "react-icons/tb"
import Header from "../../Components/Header"
import Footer from "../../Components/Footer"
import {errors} from '../../params/errors'
import { navs } from "../../params/nav"
import './error.css'

function Error ({type}) {
    let coreMessage = ''

    switch(type) {
        case '404':
            coreMessage = errors.french.types.err404
            break
        default:
    }


    return (
        <div className="content-strech">
            <Header params={navs.mainNav}/>
                <section id="error" className="error-wrapper">
                    <div className="emot-wrapper">
                        <TbFaceIdError />
                    </div>
                    <h1 className="error-title">{`${errors.french.prefix} ${type}`}</h1>
                    <h2 className="error-text">{coreMessage}</h2>
                </section>
            <Footer params={navs.social}/>
        </div>
    )
}

export default Error